<template>
    <div class="container">
        <b-modal title="Shops" id="modal" size="xl" no-close-on-esc hide-footer @hidden="model={}">
            <validation-observer v-slot="{handleSubmit}" ref="formValidator">
                <b-form role="form" @submit.prevent="handleSubmit(onSubmit)">
                    <div class="row">
                        <div class="col-md-4" v-if="!model.id">
                            <validated-input name="Owner" label="Owner" v-model="model.owner"
                                             placeholder="Enter the username"
                                             :rules="rules.name"/>
                        </div>
                        <div class="col-md-4">
                            <validated-input name="Name" label="Name" v-model="model.name" :rules="rules.name"/>
                        </div>
                        <div class="col-md-4">
                            <validated-input name="Email" label="Email" v-model="model.email" :rules="rules.email"/>
                        </div>
                        <div class="col-md-6 form-group">
                            <label class="mt-0 font-weight-600">Logo</label>
                            <b-form-file alternative name="Logo" label="Logo" v-model="model.logo"
                                         :rules="rules.logo"/>
                        </div>
                        <div class="col-md-6">
                            <validated-input name="Domain" label="Domain" v-model="model.domain"/>
                        </div>
                        <div class="col-md-6">
                            <validated-input name="Description" label="Description" v-model="model.description"
                                             :rules="rules.description"/>
                        </div>
                        <div class="col-md-6">
                            <validated-input name="Address" label="Address" v-model="model.address"
                                             :rules="rules.address"/>
                        </div>
                        <div class="col-md-3">
                            <validated-input name="Contact Number" label="Contact Number" v-model="model.contact_number"
                                             :rules="rules.contact_number" @keypress="isNumber"/>
                        </div>
                        <div class="col-md-3">
                            <validated-input name="Whatsapp Number" label="Whatsapp Number"
                                             v-model="model.whatsapp_number" :rules="rules.whatsapp_number" @keypress="isNumber"/>
                        </div>
                        <div class="col-md-3">
                            <validated-input name="Telegram Number" label="Telegram Number"
                                             v-model="model.telegram_number" :rules="rules.telegram_number" @keypress="isNumber"/>
                        </div>
                        <div class="col-md-3">
                            <validated-input name="Signal Number" label="Signal Number" v-model="model.signal_number"
                                             :rules="rules.signal_number" @keypress="isNumber"/>
                        </div>
                        <div class="col-md-6">
                            <validated-input name="longitude" label="longitude" v-model="model.longitude"
                                             :rules="rules.longitude"/>
                        </div>
                        <div class="col-md-6">
                            <validated-input name="latitude" label="latitude" v-model="model.latitude"
                                             :rules="rules.latitude"/>
                        </div>

                        <div class="col-md-12">
                            <b-button type="submit" variant="success">
                                Save
                            </b-button>
                        </div>

                    </div>
                </b-form>
            </validation-observer>
        </b-modal>

        <b-card class="mt-4">
            <b-card-title>
                <b-button variant="success" size="sm" class="float-right" @click="openModal">New Shop</b-button>
                <h3>Shops</h3>
            </b-card-title>
            <vue-table :data="data" :fields="fields" :url="url" ref="table">
                <template #image="{rowData}">
                    <img :src="rowData.logo" style="max-width: 50px"
                         class="rounded-circle" alt="">
                </template>
                <template #action="{rowData}">
                    <b-button-group>
                        <b-button size="sm" variant="primary" @click="editItem(rowData)">Edit</b-button>
                        <b-button size="sm" variant="danger" @click="deleteItem(rowData)">Delete</b-button>
                        <b-button v-if="!rowData.enabled" variant="success" class="btn btn-sm"
                                  @click="changeStatus(rowData)">Enable
                        </b-button>
                        <b-button v-if="rowData.enabled" variant="danger" class="btn btn-sm"
                                  @click="changeStatus(rowData)">Disable
                        </b-button>
                        <b-button size="sm" variant="warning" @click="viewItem(rowData)">Details</b-button>
                    </b-button-group>
                </template>
            </vue-table>
        </b-card>
    </div>
</template>

<script>
// @ is an alias to /src
import axios from 'secure-axios';
import urls from '@data/urls';

export default {
    name: 'Shop',
    components: {},
    data () {
        return {
            model: {
                owner: '',
                name: '',
                email: '',
                logo: null,
                domain: '',
                description: '',
                address: '',
                contact_number: '',
                whatsapp_number: '',
                telegram_number: '',
                signal_number: '',
                latitude: '',
                longitude: ''
            },
            rules: {
                name: {
                    required: true
                },
                email: {
                    required: true
                },
                description: {
                    required: true
                },
                address: {
                    required: true
                },
                contact_number: {
                    required: true
                },
                whatsapp_number: {
                    required: true
                },
                telegram_number: {
                    required: true
                },
                signal_number: {
                    required: true
                },
                latitude: {
                    required: true
                },
                longitude: {
                    required: true
                }
            },
            url: urls.shop.list,
            data: [],
            fields: [
                {
                    name: 'id',
                    title: 'ID',
                    sortField: 'id'
                },
                {
                    name: 'name',
                    title: 'Name',
                    sortField: 'name'
                },
                {
                    name: 'contact_number',
                    title: 'Contact Number',
                    sortField: 'contact_number'
                },
                {
                    name: '__slot:image',
                    title: 'Logo'
                },
                {
                    name: '__slot:action',
                    title: 'Action'
                }
            ]
        };
    },

    methods: {
        openModal () {
            this.$bvModal.show('modal');
        },

        setData (response) {
            this.$refs.table.refreshTable();
        },
        async loadData () {
            const response = await axios.get(urls.shop.list);
            this.setData(response);
        },

        async onSubmit () {
            let url;
            if (this.model.id) {
                url = urls.shop.update;
            } else {
                url = urls.shop.create;
            }

            const response = await axios.form(url, this.model);
            if (response.data.error) {
                this.$refs.formValidator.setErrors(response.data.errors);
            } else {
                this.setData(response);
                this.$bvModal.hide('modal');
            }
        },

        async editItem (rowData) {
            this.model = {
                id: rowData.id,
                owner: rowData.owner,
                name: rowData.name,
                email: rowData.email,
                icon_path: rowData.icon_path,
                domain: rowData.domain,
                description: rowData.description,
                address: rowData.address,
                contact_number: rowData.contact_number,
                whatsapp_number: rowData.whatsapp_number,
                telegram_number: rowData.telegram_number,
                signal_number: rowData.signal_number,
                latitude: rowData.latitude,
                longitude: rowData.longitude
            };
            this.openModal();
        },

        async deleteItem (rowData) {
            const confirm = await this.$bvModal.msgBoxConfirm('Are you sure ?');
            if (confirm) {
                const params = { id: rowData.id };
                const response = await axios.form(urls.shop.delete, params);
                this.setData(response);
            }
        },
        async viewItem (rowData) {
            const id = rowData.id;
            this.$router.push({ path: '/admin/shops/' + id + '/details/' });
        },
        changeStatus (rowData) {
            if (!confirm('Are you sure?')) {
                return;
            }
            axios.form(urls.shop.EnableOrDisable, {
                id: rowData.id
            }).then(function (data) {
                console.log('data', data);
                const json = data.data;
                console.log('json-data', json);
                console.log('json-data', json.Error);
                rowData.enabled = !rowData.enabled;
            });
        },
        isNumber: function (evt) {
            evt = (evt) || window.event;
            const charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
                ;
            } else {
                return true;
            }
        }
    }
};

</script>
